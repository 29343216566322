<template>
    <v-container style="max-width:100vw;">
        <!--totals/-->
        <!-- Filtros -->
        <v-navigation-drawer style="top: 0px; max-height:100vh!important;" right v-model="filters" :clipped="$vuetify.breakpoint.lgAndUp" app>
            <filterQuotations @filtersMovements="filtersMovements"/>
        </v-navigation-drawer>
        <!-- Contenedor show-expand -->
        <v-data-table 
        :headers="showHeaders"
        :items="movements" 
        class="elevation-0 px-6 py-4"
        height="600"
        fixed-header
        :footer-props="{'items-per-page-options':[15, 30, 50, movementsLength]}"
        v-if="showTable"
        :options.sync="options"
        :key="anIncreasingNumber" 
        :server-items-length="totalMovements"
        :loading="loading">
            <template v-slot:top>
                <v-toolbar flat class="mb-2">
                    <v-toolbar-title>Movimientos Bancarios</v-toolbar-title>

                    <!--v-btn icon v-if="filterStorageLength==0" @click="openFilter()">
                        <v-icon>mdi-filter</v-icon>
                    </v-btn>

                    <v-btn icon v-else @click="openFilter()">
                        <v-badge overlap color="primary">
                            <template v-slot:badge>
                                {{filterStorageLength}}
                            </template>
                            <v-icon>mdi-filter</v-icon>
                        </v-badge>
                    </v-btn-->

                    <v-select style="max-width:300px!important;" class="ml-4 mt-6" v-model="selectedStatus" :items="statuses" label="Status de Movimiento" item-text="text" item-value="value" dense chips outlined small-chips></v-select>

                    <v-spacer></v-spacer>

                    <v-select style="max-width:350px!important;" class="mr-4 mt-6" v-model="selectedHeaders" :items="headers" label="Columnas" dense multiple outlined return-object>
                        <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="toggle">
                            <v-list-item-action>
                                <v-icon :color="selectedHeaders.length > 0 ? 'primary' : ''">
                                {{ icon }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                Seleccionar Todo
                                </v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" x-small v-if="index < 2">
                                <span style="font-size:11px;">{{ item.text }}</span>
                            </v-chip>
                            <span v-if="index === 2" style="font-size:10px!important;" class="grey--text caption">(+{{ selectedHeaders.length - 2 }} más)</span>
                        </template>
                    </v-select>
                </v-toolbar>
            </template>
            <!-- Acciones por fila -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn  text small @click="deleteItem(item.id)"  v-show="permissions('deleteBankMovements')">
                    <v-icon small class="mr-2">
                        mdi-delete
                    </v-icon>
                    Eliminar
                </v-btn>
            </template>
            <!-- Creación -->
            <template v-slot:[`item.created_at`]="{ item }">
                {{item.created_at.slice(0, 10)}}
            </template>

            

            <template v-slot:[`item.salesId`]="{ item }">
                <div v-if="!item.is_applied&&permissions('associateBankMovements')">
                    <div v-for="(sale_id, index) in item.sales_id" :key="index">
                        <div v-if="sale_id.id==''">
                            <v-text-field class="autocompletot-ventas mt-1" v-model="sale_id.searcher" dense outlined style="width:100px;" label="Venta" @keydown.enter="addSale(item.id, index, sale_id.searcher)"></v-text-field>
                        </div>

                        <v-chip small class="mt-1" v-else close @click:close="removeSale(item.id, index)">Venta {{sale_id.id}}</v-chip>
                        
                    </div>
                    <v-row class="ma-0">
                        <v-spacer/>
                        <v-btn :disabled="item.sales_id[item.sales_id.length-1].id==''" x-small fab color="primary" class="elevation-0" style="transform:scale(0.7);" @click="item.sales_id.push({id:'', searcher:''})">
                            <v-icon small>mdi-plus</v-icon>
                        </v-btn>
                        <v-spacer/>
                    </v-row>
                </div>

                <v-btn v-else class="btnText" text :to="{ path: '/sales/'+ sale}" v-for="(sale, index3) in item.salesId" :key="index3">Venta {{sale}}</v-btn>
            </template>



            <!-- Tabla sin información -->
            <template v-slot:no-data>
                Sin movimientos bancarios a mostrar
            </template>
        </v-data-table>
        <!-- Guardar cambios -->
        <v-btn bottom color="#58c6ef" dark fab fixed right @click="save()">
            <v-icon color="white">  mdi-content-save </v-icon>
        </v-btn> 
        <!-- Crear actividad -->
        <v-dialog v-model="importDialog" max-width="900px" v-if="permissions('uploadBankMovements')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn bottom color="white" dark fab fixed right v-bind="attrs" v-on="on"  style="margin-bottom: 70px;">
                <v-icon color="#58c6ef">  mdi-upload </v-icon>
            </v-btn> 
          </template>
          <v-card class="px-12 pt-8">
            <v-file-input
                    ref="excel"
                    accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    v-model="excel"
                    label="Excel con Movimientos"
                    :loading="uploading"
                    outlined 
                ></v-file-input>
          </v-card>
        </v-dialog>
        <!-- Dialogo confirmación de eliminación -->
        <div class="text-center">
            <v-bottom-sheet  v-model="sheet" inset>
                <v-sheet class="text-center" height="150px">
                    <div class="pt-6">
                    ¿Seguro(a) que deseas borrar este movimiento?
                    </div>
                    <v-btn class="mt-4" text color="error" @click="deleteMovement()">
                    Eliminar
                    </v-btn>
                    <v-btn class="mt-4" text color="grey" @click="cancel()">
                    Cancelar
                    </v-btn>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <v-snackbar :color="snackbar.color" v-model="snackbar.show">
            {{ snackbar.message }}
        </v-snackbar>
    </v-container>
</template>

<script>
    function watchClass(targetNode, classToWatch) {
        let lastClassState = targetNode.classList.contains(classToWatch);
        const observer = new MutationObserver((mutationsList) => {
            for (let i = 0; i < mutationsList.length; i++) {
            const mutation = mutationsList[i];
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                const currentClassState = mutation.target.classList.contains(classToWatch);
                if (lastClassState !== currentClassState) {
                lastClassState = currentClassState;
                if (!currentClassState) {
                    mutation.target.classList.add('sortHandle');
                }
                }
            }
            }
        });
        observer.observe(targetNode, { attributes: true });
    }
    import axios from "axios";
    import Filter from "../sales/filter"
    export default {
        components: {
            'filterQuotations':Filter
        }, 
        data: () => ({
            snackbar: {
                show: false,
                message: null,
                color: null
            },
            anIncreasingNumber: 1,
            sheet: false,
            filters: false,
            importDialog: false,
            deleteId:'',
            movements: [],
            headers:[],
            selectedHeaders: [],
            selectedStatus: 'todo',
            statuses:[
                { text:'Aplicado', value:1 },
                { text:'No Aplicado', value:0 },
                { text:'Todo', value:'todo' },
            ],
            options: {},
            showTable:true,
            totalMovements:0,
            loading:false,
            movementsLength:0,
            uploading:false,
            excel:'',
            salesList:[],
            isLoadingSales:false,
            searchSales:'',
            nada:''
        }),
        computed: {
            filterStorageLength(){
                return localStorage.getItem("filtersMovementsLength")*1
            },
            showHeaders () {
                if(this.movements!=''){
                    return this.headers.filter(s => this.selectedHeaders.includes(s));
                }
            },
            currentUser(){
                return this.$store.state.currentUser.user;
            },
            headersMap(){ 
                return [
                { text: 'Folio', value: 'id' },
                { text: 'Venta(s)', value: 'salesId' },

                {value: 'account', text: 'Cuenta' },
                {value: 'date', text: 'Fecha' },
                {value: 'hour', text: 'Hora' },
                {value: 'branch', text: 'Sucursal' },
                {value: 'description', text: 'Descripcion' },
                {value: 'amount', text: 'Importe' },
                {value: 'balance', text: 'Slado' },
                {value: 'reference', text: 'Referencia' },
                {value: 'concept', text: 'Concepto' },
                {value: 'bank', text: 'Banco Participante' },
                {value: 'beneficiary_clabe', text: 'Clabe Beneficiario' },
                {value: 'beneficiary_name', text: 'Nombre Beneficiario' },
                {value: 'payer_account', text: 'Cta Ordenante' },
                {value: 'payer_name', text: 'Nombre Ordenante' },
                {value: 'return_cause', text: 'Codigo Devolucion' },
                {value: 'return_code', text: 'Causa Devolucion' },
                {value: 'beneficiary_rfc', text: 'RFC Beneficiario' },
                {value: 'payer_rfc', text: 'RFC Ordenante' },
                {value: 'tracking_key', text: 'Clave de Rastreo' },

                { text: 'Fecha Importación', value: 'created_at' },
                { value: 'actions', sortable: false, align: 'end', },
            ]},
            likesAllFruit () {
                return this.selectedHeaders.length === this.headersMap.length
            },
            likesSomeFruit () {
                return this.selectedHeaders.length > 0 && !this.likesAllFruit
            },
            icon () {
                if (this.likesAllFruit) return 'mdi-close-box'
                if (this.likesSomeFruit) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },
        },
        created () {
            this.headers = Object.values(this.headersMap);
            this.selectedHeaders = this.headers;
        },
        watch: {
            searchSales(val){
                this.salesList = []
                if (this.isLoadingSales) return
                this.isLoadingSales = true
                axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/sales?filter[id]=' + val +'&filter[pending_sales]=1&status=vendido').then(response=>{
                    this.salesList = response.data.data
                }).finally(() => (this.isLoadingSales = false))
            },
            excel:{
                handler(){    
                    let formData = new FormData();
                    formData.append('file', this.excel);
                    this.uploading = true
                    axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/bank_movement/import"
                        ,formData
                        ,{headers: {"Content-Type": "multipart/form-data"}}
                    ).then(response=>{
                        this.uploading = false
                        this.importDialog = false
                        this.snackbar = {
                            color:'success',
                            show:true,
                            message:'Archivo importado con éxito',
                        }
                        this.getDataFromApi()
                    }).catch(error => {
                        this.snackbar = {
                            message: 'Error al cargar el archivo',
                            color: 'error',
                            show: true
                        }
                    })
                },deep:true,
            },
            options: {
                handler () {
                    if(JSON.stringify(this.currentUser).length>10){
                        this.getDataFromApi()
                    }
                },
                deep: true,
            },
            selectedStatus: {
                handler () {
                    this.getDataFromApi()
                },
                deep: true,
            },
            currentUser: {
                handler () {
                    this.getDataFromApi()
                },
                deep: true,
            },
        },
        methods: {
            addSale(item_id, index, sale){
                axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/sales?filter[id]=' + sale +'&filter[pending_sales]=1&status=vendido').then(response=>{
                    if(response.data.data.length>0){
                        const maped_item = this.movements.filter(mov=>mov.id == item_id)[0]
                        this.movements[this.movements.indexOf(maped_item)].sales_id[index].id = response.data.data[0].id
                    }
                    else if(response.data.data.length>1){
                        this.snackbar = {
                            message: 'Se encontraron las siguientes ventas relacionadas: ' + response.data.data.map(id=>id.id).toString,
                            color: 'primary',
                            show: true
                        }
                    }else{
                        this.snackbar = {
                            message: 'No se encontraron ventas relacionadas',
                            color: 'error',
                            show: true
                        }
                    }
                })
            },
            removeSale(item_id, index){
                const maped_item = this.movements.filter(mov=>mov.id == item_id)[0]
                this.movements[this.movements.indexOf(maped_item)].sales_id[index].id = ''
            },
            save(){
                var items = this.movements.filter(item=>item.sales_id[0].id!=undefined&&item.sales_id[0].id!=null&&item.sales_id[0].id!='').map(id=>{return{
                    id:id.id,
                    salesId: id.sales_id.map(sale=>sale.id),
                    is_applied: true
                }})
                axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/bank_movement/bulk_update", items).then(response=>{
                    this.getDataFromApi()
                    this.snackbar = {
                        color:'success',
                        show:true,
                        message:'Movimientos asignados con éxito',
                    }
                }).catch(error => {
                    this.snackbar = {
                        message: 'Error al asignar movimientos',
                        color: 'error',
                        show: true
                    }
                })
            },
            getDataFromApi () {
                this.loading = true
                this.apiCall().then(data => {
                    this.movements = data.items
                    this.totalMovements = data.total
                    this.loading = false
                })
            },
            apiCall () {
                return new Promise((resolve, reject) => {
                    this.filterStorageLength = localStorage.getItem('filtersBankMovementsLength')
                    const { sortBy, sortDesc, page, itemsPerPage } = this.options
                    var items = []
                    var total = 0
                    var link = this.$store.state.currentUser.sale_filter
                    if(localStorage.getItem('filtersBankMovements')!=null){
                        link = link + JSON.parse(localStorage.getItem('filtersBankMovements'))+'&'
                    }
                    if(this.selectedStatus!='todo'){
                        link = link + 'filter[is_applied]=' + this.selectedStatus + '&'
                    }
                    if(sortBy.length === 1){
                        if(sortDesc[0]){
                            link = link + "sort=-" + sortBy[0] + '&'
                        }else{
                            link = link + "sort=" + sortBy[0] + '&'
                        }
                    }
                    axios.get(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/bank_movements?" + link + "page=" + page + "&itemsPerPage=" + itemsPerPage + '&status=vendido').then(response => {
                        this.movementsLength = response.data.meta.total
                        items = response.data.data.map(id=>{return{
                            ...id,
                            sales_id: [{id:'', searcher:''}],
                            
                        }})
                        total = response.data.meta.total
                        resolve({
                            items,
                            total,
                        })
                    })
                })
            },
            toggle () {
                this.$nextTick(() => {
                    if (this.likesAllFruit) {
                        this.selectedHeaders = []
                    } else {
                        this.selectedHeaders = this.headersMap.slice()
                    }
                })
            },
            openFilter(){
                if(this.filters == false){
                    this.$emit("closeDrawer", false);
                    this.filters = true
                }else{
                    this.$emit("closeDrawer", true);
                    this.filters = false
                }
            },
            permissions(permission){
                if(this.currentUser.id==1){
                    return true
                }else if(this.currentUser.permissions!=undefined){
                    if(this.currentUser.permissions.includes(permission)){
                        return true
                    }else{
                        return false
                    }
                }else{
                    return false
                }
            },
            filtersMovements: function(params) {
                this.getDataFromApi()
            },
            exportExcel: function () {
                let data = XLSX.utils.json_to_sheet(this.movements)
                const workbook = XLSX.utils.book_new()
                const filename = 'Lista de Cotizaciones'
                XLSX.utils.book_append_sheet(workbook, data, filename)
                XLSX.writeFile(workbook, `${filename}.xlsx`)
            },
            deleteMovement(){
                axios.delete(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/bank_movements/"+this.deleteId).then(response => {
                    this.deleteId = ''
                    this.sheet = false
                    this.getDataFromApi()
                });
            },
            cancel(){
                this.deleteId = ''
                this.sheet = false
            },
            deleteItem (id) {
                this.deleteId = id
                this.sheet = true
            },
        }
    }
</script>
<style>
    .autocompletot-ventas .v-text-field__details{
        display: none!important;
    }
    .autocompletot-ventas label{
        font-size: 14px!important;
    }
    .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
        box-shadow: none!important;
        background: #f7f7f7;
    }
    .style-1 {
        background-color: #fff2ca;
    }
    .style-2 {
        background-color: #d9ebff;
    }
    .style-3 {
        background-color: #0080002b;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
        padding: 0 8px!important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
        font-size:13px!important;
    }
    .v-row-group__header .ma-0.v-btn.v-btn--icon.v-btn--round.theme--light.v-size--small:nth-child(2){
        display:none!important;
    }
    .v-data-table-header th {
        white-space: nowrap;
    }
    .theme--light.v-data-table .v-row-group__header, .theme--light.v-data-table .v-row-group__summary {
        background: #f4f5fa;
    }
    .v-text-field--outlined fieldset {
        border-color: #e0e0e0;
    }
    .v-list-item__action {
        margin: 0px 20px 0px 0px!important;
    }
    .v-menu__content.theme--light.menuable__content__active{
        max-height: 85vh!important;
    }

    .v-data-table>.v-data-table__wrapper>table>thead>tr>th {
        padding: 0 0px!important;
    }
    .btnText2{
        padding: 0px!important;
        height: auto!important;
        background: rgb(219, 219, 219)!important;
        box-shadow: none!important;
        /*text-transform: none;*/
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        letter-spacing: 0;
        color: #1f1f1f!important;
        text-decoration: none!important;
    }
    .btnText{
    padding: 0px!important;
    height: auto!important;
    background: white!important;
    box-shadow: none!important;
    /*text-transform: none;*/
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0;
}
</style>